import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select";

// Connects to data-controller="select"
export default class extends Controller {
  static targets = [ "field"]
  connect() {
    const field = this.fieldTarget
    
    new TomSelect(field, {
      create: false,
      sortField: {
        field: "text",
        direction: "asc"
      }
    });
  }
}
