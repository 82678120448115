import consumer from "./consumer"

consumer.subscriptions.create("CallChannel", {
  connected() {
    const dataDiv = document.getElementById("data")
    if (dataDiv) {
      const data = dataDiv.dataset
      const type = data.type
      const unit = data.unit
      console.log("Welcome " + type )
      console.log("Unit: " + unit)
    }
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    const dataDiv = document.getElementById("data")
    if (dataDiv) {
      const ddata = dataDiv.dataset
      if (data.action == "call" && ddata.type === "tenant" && data.unit === ddata.unit) {
        console.log(data)
        document.getElementById("inCall").style = "display: block"
        document.getElementById("answerBtn").href = "/tenant_answer?zone=" + data.zone 
      }
      if (data.action == "room" && data.unit === ddata.unit ) {
        const btn = document.getElementById("enterRoomBtn");
        btn.href = "/room/" + data.name
        btn.click();
        console.log(data)
      }
    }
  }
});
