import { Controller } from "@hotwired/stimulus"
import { Modal } from '@coreui/coreui-pro/dist/js/coreui.bundle';

export default class extends Controller {
  static targets = [];

  connect() {
    this.modal = new Modal(this.element);
    this.modal.show();
    // document.addEventListener('turbo:submit-end', this.handleSubmit)
    console.log('modal connect')
  }

  disconnect() {
    document.removeEventListener('turbo:submit-end', this.handleSubmit)
  }

  close() {
    // Remove the modal element so it doesn't blanket the screen
    this.modal.hide();

    // Remove src reference from parent frame element
    // Without this, turbo won't re-open the modal on subsequent clicks
    this.element.closest("turbo-frame").src = undefined
  }

  handleKeyup(e) {
    console.log('Handle')
    if (e.code == "Escape") {
      this.modal.hide()
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    window.e = e

    if (e.detail.success) {
      if (e.detail.fetchResponse.redirected) {
        var url = e.detail.fetchResponse.response.url
        var modal = document.getElementById('modal')
        modal.addEventListener('hidden.coreui.modal', function (event) {
          console.log(url)
          Turbo.visit(url, { action: "visit" })
        })
        this.modal.hide()
      }
    }
  }

}