import "@hotwired/turbo-rails"
import "./controllers"
import "@fortawesome/fontawesome-free/js/all"
import "@coreui/coreui-pro"
import "simplebar/dist/simplebar.min"
// import "../components/*.js"

import coreui from "@coreui/coreui-pro/dist/js/coreui.bundle.min"
window.coreui = coreui
import "./channels"
