import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    name: String,
    url: String, 
    size: { type: String, default: 'modal-md' } }
  
  click(){
    let modal = document.getElementById("modalShell")
    let modalController = this.application.getControllerForElementAndIdentifier(
      modal, "modal"
    )
    modalController.open(this.nameValue, this.urlValue, this.sizeValue);
  }
}