import { Controller } from "@hotwired/stimulus"
import { Modal } from 'bootstrap'

// Connects to data-controller="reload"
export default class extends Controller {
  connect() {
    var modal = document.getElementById('modal');
    if (modal) {
      new Modal(modal).hide();
    }
    Turbo.visit(window.location, { action: 'replace' })
  }
}
