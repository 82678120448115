import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="video"
export default class extends Controller {
  static values = {
    room: String
  }

  connect() {
    this.serup();
  }

  serup() {
    fetch('/twilio/token')
    .then(response => response.json())
    .then(data => {
      const token = data.token;
      this.twilioConnect(token);

    }
  );  
  }
  
  twilioConnect(token){
    const Video = Twilio.Video;
    Video.connect(token, { 
      name: this.roomValue,
      audio: true,
      video: { width: 640 }
    }).then(room => {
      console.log(`Successfully joined a Room: ${room}`);
      const localParticipant = room.localParticipant;
      console.log(`Connected to the Room as LocalParticipant "${localParticipant.identity}"`);


      // Log any Participants already connected to the Room
      room.participants.forEach(participant => {
        console.log(`Participant "${participant.identity}" is connected to the Room`);
        this.attach(participant);
      });

      // Log new Participants as they connect to the Room
      room.once('participantConnected', participant => {
        this.attach(participant);
      });

      // Log Participants as they disconnect from the Room
      room.once('participantDisconnected', participant => {
        console.log(`Participant "${participant.identity}" has disconnected from the Room`);
        room.localParticipant.tracks.forEach(publication => {
          const attachedElements = publication.track.detach();
          attachedElements.forEach(element => element.remove());
        });      
      });

  

    }, error => {
      console.error(`Unable to connect to Room: ${error.message}`);
    });

    Video.createLocalVideoTrack().then(track => {
      const localMediaContainer = document.getElementById('local-media');
      localMediaContainer.appendChild(track.attach());
    });

        



  }

  attach(participant){
    console.log(`Participant "${participant.identity}" has connected to the Room`);
    participant.tracks.forEach(publication => {
      if (publication.isSubscribed) {
        const track = publication.track;
        document.getElementById('remote-media').appendChild(track.attach());
      }
    });

    participant.on('trackSubscribed', track => {
      document.getElementById('remote-media').appendChild(track.attach());
    });

  }
  disconnect() {
    console.log('disconnect');
  }

}
