// hello_controller.js
import { Controller } from "@hotwired/stimulus"
import { BrowserQRCodeReader } from '@zxing/library';

export default class extends Controller {
  static targets = [ "video", "scanButton", "result", "field", "form" ]

  connect() {
    console.log("scan connected")
    this.codeReader = new BrowserQRCodeReader();
    this.initScan();
    this.fieldTarget.focus();
  }

 initScan(){
  console.log("init scan")

  this.codeReader
    .listVideoInputDevices()
    .then(videoInputDevices => {
      videoInputDevices.forEach(device =>
        console.log(`${device.label}, ${device.deviceId}`)
      );
    })
    .catch(err => console.log(err));

  }

  start(){
    this.scanButtonTarget.style = "display:none;";
    // this.formTarget.style = "display:none;"
    this.videoTarget.style = "display:block;"
    this.codeReader.decodeFromInputVideoDevice(undefined, 'video').then((result) => {
      // scanned action 
      this.fieldTarget.value = result.text 
      if(this.fieldTarget.value = result.text){
        this.videoTarget.style = "display:none;"
        this.scanButtonTarget.style = "display:block;";
      }
    }).catch((err) => {
      // scan error
      console.error(err)
      this.resultTarget.innerHTML = "<p>" + err + "</p>"
    })
  }
}
